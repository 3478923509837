import React, { ComponentType, LazyExoticComponent } from 'react'

function lazyWithPreload<T>(factory: () => any) {
  const Component: LazyExoticComponent<ComponentType<T>> & { preload?: () => any } =
    React.lazy(factory)
  Component.preload = factory
  return Component
}

export default lazyWithPreload
