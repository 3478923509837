export enum PlausibleEvent {
  PopupDisplayed = `popupDisplayed`,
  ClickOnPopup = `clickOnPopup`,
  RegAfterClickOnPopup = `regAfterClickOnPopup`,
  ClosePopup = 'closePopup',
  OpenRegisterPage = `openRegisterPage`,
  SubmitRegister = `submitRegister`,
  OpenMainPage = `openMainPage`,
  UserRegistered = `userRegistered`,
  ClickedSocials = `clickedSocials`,
  ClickedLanguagesSelection = `clickedLanguagesSelection`,
  ClickedLanguagesLang = `clickedLanguagesLang`,
  ClickedScrollUp = `clickedScrollUp`,
  ClickedMenuItem = `clickedMenuItem`,
  OpenedBinany = `openedBinany`,
  OpenedSignIn = `openedSignIn`,
  ClickedOnSocialSignIn = `clickedOnSocialSignIn`,
  ClickedSignIn = `clickedSignIn`,
  ClickedSignInForgot = `clickedSignInForgot`,
  ClickedSignInSendRecovery = `clickedSignInSendRecovery`,
  Authorised = `authorised`,
  NotAuthorised = `notAuthorised`,
  OpenedSignUp = `openedSignUp`,
  ClickedOnSocialSignUp = `clickedOnSocialSignUp`,
  OpenedCurrencySelecting = `openedCurrencySelecting`,
  ClickedSignUp = `clickedSignUp`,
  Registered = `registered`,
  NotRegistered = `notRegistered`,
  ClickedRedMoreReviews = `clickedRedMoreReviews`,
  ClickedleftOwnReview = `clickedleftOwnReview`,
  ClickedStatsCards = `clickedStatsCards`,
  SentContactsMessage = `sentContactsMessage`,
  ClickedSendEmail = `clickedSendEmail`,
  OpenedContacts = `openedContacts`,
  OpenedWhatis = `openedWhatis`,
  OpenedAgreement = `openedAgreement`,
  OpenedPrivacy = `openedPrivacy`,
  OpenedStats = `openedStats`,
}

export enum PopupType {
  OnCloseTab = `onCloseTab`,
  OnDownScroll = `onDownScroll`,
  OnTimeSpacing = `onTimeSpacing`,
}

export enum RegistrationTypes {
  Google = `google`,
  RegularReg = `regularReg`,
}

export enum SocialLocation {
  Footer = `footer`,
  Contacts = `contacts`,
}

export enum MenuItemLocation {
  Footer = `footer`,
  Header = `header`,
  Content = `content`,
}
