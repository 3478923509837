'use client'

import { createContext, useContext } from 'react'

export type Info = {
  code: string
  country: string
  restricted: boolean
}

type InfoProviderContextType = Info

const InfoProviderContext = createContext<InfoProviderContextType>(undefined as any)

export function useInfoProviderContext() {
  const context = useContext<InfoProviderContextType>(InfoProviderContext)

  // if (context === undefined) {
  //   throw new Error('useInfoProviderContext must be within Provider')
  // }

  return context
}

export default InfoProviderContext
