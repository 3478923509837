import { useCallback, useEffect, useRef } from 'react'
import useMount from 'react-use/lib/useMount'

const useTelegramMiniapp = () => {
  const ref = useRef<any>(null)

  useMount(() => {
    ref.current = window
  })

  useEffect(() => {
    const tgWebapp = ref.current?.Telegram?.WebApp

    if (tgWebapp) {
      tgWebapp.ready()

      if (tgWebapp.isExpanded === false) tgWebapp.expand()
      if (tgWebapp.isClosingConfirmationEnabled === false) tgWebapp.enableClosingConfirmation()
      if (tgWebapp.isVerticalSwipesEnabled === true) tgWebapp.disableVerticalSwipes()
    }
  }, [ref.current?.Telegram?.WebApp])

  const getWebAppAsync: () => Promise<any> = useCallback(async () => {
    return new Promise((resolve) => {
      const checkInterval = 100
      const maxAttempts = 100

      let attempts = 0

      const check = () => {
        if ((window as any)?.Telegram?.WebApp !== undefined) {
          resolve((window as any)?.Telegram?.WebApp)
        } else if (attempts < maxAttempts) {
          // eslint-disable-next-line no-plusplus
          attempts++
          setTimeout(check, checkInterval)
        }
      }

      check()
    })
  }, [])

  return {
    initData: ref.current?.Telegram?.WebApp?.initData as string,
    getWebAppAsync,
  }
}

export default useTelegramMiniapp
