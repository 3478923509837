import loadGeoInfo from 'api/loadGeoInfo'
import { useEffect, useState } from 'react'

type Payload = {
  code: string
  country: string
  restricted: boolean
}

const useInfoProvider = () => {
  const [payload, setPayload] = useState<Payload | undefined>(undefined)

  useEffect(() => {
    const fetchGeo = async () => {
      try {
        const res = await loadGeoInfo()
        setPayload(res.data.payload)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }

    fetchGeo()
  }, [])

  return {
    ...payload,
  }
}

export default useInfoProvider
