import Cookies from 'js-cookie'
import { useMemo } from 'react'
import setTokensAndRedirect from 'utils/setTokensAndRedirect'

import { useRouter } from 'next/router'

const useRedirectWithTokensFromQuery = () => {
  const router = useRouter()
  const { query } = router

  const toRedirect = useMemo(() => {
    if (query.accessToken && query.refreshToken) {
      Cookies.set('justRegisteredOnPromo', 'true')
      localStorage.setItem('justRegisteredOnPromo', 'true')
      setTokensAndRedirect(query.accessToken, query.refreshToken)

      return true
    }

    return false
  }, [router])

  return toRedirect
}

export default useRedirectWithTokensFromQuery
