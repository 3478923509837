import axios, { AxiosRequestConfig } from 'axios'
import uuidv4 from 'utils/uuid'

interface IApiFetchOptions extends AxiosRequestConfig {
  withAuth?: boolean
  formData?: boolean
}

const apiFetch = (url: string, options: IApiFetchOptions = {}) => {
  const defaultOptions: IApiFetchOptions = {
    method: `GET`,
    headers: {
      'Content-Type': `application/json`,
      'X-Trace-Id': uuidv4(),
    },
    validateStatus: (status) => status !== 401,
    baseURL: process.env.NEXT_PUBLIC_REACT_APP_API_URL,
  }

  return axios(url, { ...defaultOptions, ...options })
}

export default apiFetch
