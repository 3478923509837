import { PropsWithChildren } from 'react'
import InfoProviderContext, { Info } from './InfoProviderContext'
import useInfoProvider from './useInfoProvider'

type InfoProviderProps = PropsWithChildren

const InfoProvider = (props: InfoProviderProps) => {
  const { children } = props

  const info = useInfoProvider()

  return <InfoProviderContext.Provider value={info}>{children}</InfoProviderContext.Provider>
}

export default InfoProvider
