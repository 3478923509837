import { useCallback, useEffect, useState } from 'react'

const useFacebookSDK = () => {
  const initFacebookSdk = useCallback(async () => {
    return new Promise<void>((resolve) => {
      ;(window as any).fbAsyncInit = () => {
        ;(window as any).FB.init({
          appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v20.0',
          nonce: 'kgvWVBWg',
        })

        resolve()
      }
    })
  }, [])

  const getFacebookLoginStatus = useCallback(async () => {
    return new Promise((resolve) => {
      ;(window as any)?.FB?.getLoginStatus((response) => {
        resolve(response)
      })
    })
  }, [initFacebookSdk])

  const fbLogin = useCallback(async () => {
    return new Promise((resolve) => {
      ;(window as any).FB.login((response) => {
        resolve(response)
      })
    })
  }, [])

  const getIsFBReady: () => Promise<any> = useCallback(async () => {
    return new Promise((resolve) => {
      const checkInterval = 50
      const maxAttempts = Infinity

      let attempts = 0

      const check = () => {
        if ((window as any)?.FB !== undefined) {
          resolve(true)
        } else if (attempts < maxAttempts) {
          // eslint-disable-next-line no-plusplus
          attempts++
          setTimeout(check, checkInterval)
        }
      }

      check()
    })
  }, [])

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    async function getIsFBReadyAsync() {
      await getIsFBReady()

      setIsReady(true)
    }

    getIsFBReadyAsync()
  }, [])

  return {
    initFacebookSdk,
    getFacebookLoginStatus,
    fbLogin,
    isReady,
  }
}

export default useFacebookSDK
