import { PlausibleEvent } from 'enums/plausible'
import Plausible from 'plausible-tracker'
import { useCallback, useEffect } from 'react'
import useAmplitude from './useAmplitude'

const { trackEvent } = Plausible()

const plausibleApiHost = process.env.NEXT_PUBLIC_PLAUSIBLE_API_HOST

const usePlausible = () => {
  const props: Record<string, any> = {} // пропсы, которые общие для всех событий

  const { track } = useAmplitude()

  useEffect(() => {
    if (navigator.language) {
      props.browserLanguage = navigator.language
    }
  }, [])

  function trackEventWithAsyncOption<B = undefined>(
    plausibleEvent: PlausibleEvent,
    otherProps?: Record<string, string | number | boolean>,
    async?: B | boolean,
  ): B extends undefined ? void : Promise<PlausibleEvent> {
    if (async) {
      return new Promise((resolve) => {
        trackEvent(
          plausibleEvent,
          {
            props: { ...props, ...otherProps },
            callback: () => resolve(plausibleEvent),
          },
          {
            trackLocalhost: true,
            apiHost: plausibleApiHost,
          },
        )

        track(plausibleEvent, { ...props, ...otherProps })
      }) as B extends undefined ? void : Promise<PlausibleEvent>
    }

    trackEvent(
      plausibleEvent,
      {
        props: { ...props, ...otherProps },
      },
      {
        trackLocalhost: true,
        apiHost: plausibleApiHost,
      },
    )

    track(plausibleEvent, { ...props, ...otherProps })

    return undefined
  }

  const setEvent = useCallback(trackEventWithAsyncOption, [])

  return setEvent
}

export default usePlausible
