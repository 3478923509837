import { useEffect } from 'react'

const useDisablingZoom = () => {
  useEffect(() => {
    const preventZoom = (e) => e.preventDefault()
    document.addEventListener('gesturestart', preventZoom)
    return () => {
      document.removeEventListener('gesturestart', preventZoom)
    }
  }, [])
}

export default useDisablingZoom
